import PauseImage from './images/pause.png'
import PlayImage from './images/play.png'

(function() {
  var audio_controls, audio_has_played, current_time, getCookie, is_playing, playing, save_state, setCookie, set_audio, start_audio, whitelisted;

  setCookie = function(c_name, value) {
    var c_value, expiry;
    expiry = new Date();
    expiry.setTime(expiry.getTime() + (10 * 60 * 1000));
    c_value = escape(value) + "; expires=" + expiry.toUTCString();
    return document.cookie = c_name + "=" + c_value;
  };

  getCookie = function(c_name) {
    var c_end, c_start, c_value;
    c_value = document.cookie;
    c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start === -1) {
      c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start === -1) {
      c_value = null;
    } else {
      c_start = c_value.indexOf("=", c_start) + 1;
      c_end = c_value.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
  };

  current_time = 0;

  playing = true;

  audio_has_played = false;

  audio_controls = function() {
    if (/Mobi/.test(navigator.userAgent)) {
      return;
    }
    return $('#pause_play').click(function() {
      var audio;
      audio = document.getElementById("player");
      if (is_playing()) {
        $('#pause_play').css('background-image', `url(${PlayImage})`);
        setCookie('boogies_music', 'false');
        return audio.pause();
      } else {
        $('#pause_play').css('background-image', `url(${PauseImage})`);
        setCookie('boogies_music', 'true');
        return audio.play();
      }
    });
  };

  save_state = function() {
    window.stop();
    current_time = document.getElementById("player").currentTime;
    return playing = is_playing();
  };

  whitelisted = function() {
    return navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('Opera') !== -1;
  };

  start_audio = function() {
    var audio;
    if (/Mobi/.test(navigator.userAgent)) {
      return;
    }
    set_audio();
    audio = document.getElementById("player");
    if (whitelisted() || !audio_has_played) {
      if (playing && getCookie('boogies_music') === 'true') {
        audio.play();
        $('#pause_play').css('background-image', `url(${PauseImage})`);
      } else if (!playing || getCookie('boogies_music') === 'false') {
        audio.pause();
        $('#pause_play').css('background-image', `url(${PlayImage})`);
      } else {
        audio.play();
        $('#pause_play').css('background-image', `url(${PauseImage})`);
      }
    } else if (audio_has_played) {
      audio.pause();
      $('#pause_play').css('visibility', 'hidden');
    }
    return audio_has_played = true;
  };

  set_audio = function() {
    var audio;
    $("#mp3Source").attr("src", "/music/song2.mp3").detach().appendTo("#player");
    audio = document.getElementById("player");
    if (whitelisted()) {
      audio.addEventListener("canplaythrough", function() {
        return audio.currentTime = current_time;
      });
    }
    if (whitelisted() || !audio_has_played) {
      if (is_playing()) {
        return $('#pause_play').css('background-image', `url(${PauseImage})`);
      } else {
        return $('#pause_play').css('background-image', `url(${PlayImage})`);
      }
    }
  };

  is_playing = function() {
    var audio;
    audio = document.getElementById("player");
    return !audio.paused && !audio.ended && 0 < audio.currentTime;
  };

  $(document).ready(audio_controls);

  $(window).on('load', start_audio);

  $(document).on('page:load', audio_controls);

  $(document).on('page:fetch', save_state);

  $(document).on('page:load', start_audio);

}).call(this);